export class SelectAll {
    static setup() {
        $(document).ready(function () {
            $('#select_all_none').click(function (event) {
                if (this.checked) {
                    $('.checkbox').each(function () {
                        $(this).prop('checked', true);
                    });
                } else {
                    $('.checkbox').each(function () {
                        $(this).prop('checked', false);
                    });
                }});
        });
    }
}
