import '../src/css/variables.scss';
import '../src/css/general.scss';
import '../src/css/forms.scss';
import '../src/css/tasks.scss';
import '../src/css/dates.scss';
import '../src/css/timer';

import { DynamicSelectable } from '../src/dynamicSelectable';
DynamicSelectable.setup();

import { Pages } from '../src/pages';
Pages.setup();

import { SelectAll } from '../src/select_all';
import { RecentTasks } from '../src/recentTasks';

$(document).ready(function() {
  SelectAll.setup();
  RecentTasks.setup();
});
